import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import '@/libs/flexible';

import Pagination from 'ant-design-vue/lib/pagination';
import 'ant-design-vue/lib/pagination/style';
import Popover from 'ant-design-vue/lib/popover';
import 'ant-design-vue/lib/popover/style';

Vue.use(Pagination);
Vue.use(Popover);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

<template>
  <div class="page-home">
    <div class="page-title">
      <span>关于英仕健康</span>
      <span class="en">/ About Us</span>
    </div>
    <div class="page-desc">
      英仕健康于2017年2月在上海成立，作为国内领先的健康医疗险管理平台，专注于健康险产品创新设计、全流程风控管理，通过建立保险与医疗资源的深度结合，运用智能核保、HMO、PMB等方式进行健康险全流程管理。
    </div>
    <div class="more" id="index-2" @click="toMore">了解更多 >></div>
    <div class="topinfo">
      <div class="item" v-for="item in topInfos" :key="item.label">
        <div class="item-title">
          {{ item.title }}<span class="subtit">{{ item.subtitle }}</span>
        </div>
        <div class="item-label">{{ item.label }}</div>
      </div>
    </div>
    <div class="product">
      <div :class="`m-title ${showProduct ? 'show-history' : ''}`">
        <p>PRODUCT INTRODUCTION</p>
        <p class="t-top" id="index-0">产品介绍</p>
      </div>
      <div :class="`m-desc ${showProduct ? 'show-history-desc' : ''}`">
        英仕健康通过科技创新助推健康险快速发展，打造数据科技化、风控科技化、医疗科技化、理赔科技化的全流程健康管理科技平台，自主研发的智能核保系统全自动高效核保，通过管理式医疗服务平台，对客户进行精准化的医疗管理。
      </div>
      <div :class="`product-list ${showProduct ? 'show-history-content' : ''}`">
        <div class="product-list-item" v-for="item in productList" :key="item.title">
          <img :src="item.img" alt="" />
          <div class="product-list-item-title">{{ item.title }}</div>
          <div class="product-list-item-desc">{{ item.desc }}</div>
          <div class="product-list-item-more" @click="toProduct(item)">了解更多 >></div>
        </div>
      </div>
    </div>
    <div class="service">
      <div :class="`m-title ${showService ? 'show-history' : ''}`">
        <p>SERVICE INTRODUCTION</p>
        <p class="t-top" id="index-1">服务介绍</p>
      </div>
      <div :class="`m-desc ${showService ? 'show-history-desc' : ''}`">
        英仕健康整合全球60余项医疗健管增值服务，包括绿通服务、住院直付、基因检测、MDT多学科会诊、特药服务、海外医疗等，为被保人带去真正有温度的健康保障。
      </div>
      <div :class="`service-list-box`">
        <img :class="`icon icon-l`" v-if="serviceScrL" src="../assets/imgs/icon-right.png" alt="" @click="scrollLeft" />
        <img :class="`icon`" v-if="serviceScr" src="../assets/imgs/icon-right.png" alt="" @click="scrollRight" />
        <div class="service-list" @scroll="serviceScroll">
          <div class="service-list-item" v-for="item in serviceList" :key="item.title">
            <img :class="`${showService ? 'show-history-content' : ''}`" :src="item.img" alt="" />
            <div :class="`service-list-item-title ${showService ? 'show-history-content2' : ''}`">
              {{ item.title }}
            </div>
            <div :class="`service-list-item-desc ${showService ? 'show-history-content3' : ''}`">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partner">
      <div :class="`m-title ${showPartner ? 'show-history' : ''}`">
        <p>COOPERATIVE PARTNER</p>
        <p class="t-top" id="index-3">合作伙伴</p>
      </div>
      <div :class="`m-desc ${showPartner ? 'show-history-desc' : ''}`">
        英仕健康已与中国人寿保险、中国人民保险、中国太平保险、中国平安保险等20余家保险公司建立合作，设计开发超过40款创新型健康险产品，产品线已覆盖百万医疗、中高端医疗险、防癌险、专病专药险等。
      </div>
      <div class="partner-list">
        <div :class="`partner-list-item ${showPartner ? `partner-item-${parseInt(idx / 6)}` : ''}`" v-for="(item, idx) in partnerList" :key="String(idx)">
          <img :src="item.img" />
        </div>
      </div>
    </div>
    <div class="location">
      <div :class="`m-title ${showMap ? 'show-history' : ''}`">
        <p>COMPANY ADDRESS</p>
        <p class="t-top" id="index-3">公司地址</p>
      </div>
      <div :class="`m-desc ${showMap ? 'show-history-desc' : ''}`">
        <img src="../assets/imgs/map.svg" style="width: 16px; margin-top: -1px" alt="" />上海市杨浦区控江路1500号联创国际4号楼
      </div>
      <div class="location-list">
        <!-- <img style="width: 100%" src="../assets/imgs/map.png" alt="" /> -->
        <div id="container" style="width: 100%; height: 260px"></div>
        <!-- <div class="location-txt"><img src="../assets/imgs/map.svg" style="width: 20px" alt="" />上海市杨浦区控江路1500号联创国际4号楼</div> -->
      </div>
    </div>

    <div class="contact-bar" v-if="showContact">
      <div class="contact-bar-item" v-for="item in contactBar" :key="item.title">
        <a-popover placement="left">
          <template slot="content">
            <div class="popover">
              <img v-if="item.qrcode" :src="item.qrcode" alt="" />
              <p v-if="item.desc">{{ item.desc }}</p>
            </div>
          </template>
          <div class="inner-box">
            <div class="inner-item-box">
              <div class="icon-gzh" v-if="item.id === 'gzh'"></div>
              <div class="icon-xcx" v-if="item.id === 'xcx'"></div>
              <div class="icon-email" v-if="item.id === 'email'"></div>
              <div class="icon-tel" v-if="item.id === 'tel'"></div>
              <div class="icon-kf" v-if="item.id === 'kf'"></div>
              <!-- <div class="title">{{ item.title }}</div> -->
            </div>
          </div>
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery';
export default {
  name: 'HomeView',
  data() {
    return {
      screenWidth: document.body.clientWidth,
      serviceScr: true,
      serviceScrL: false,
      showContact: false,
      showProduct: false,
      showService: false,
      showPartner: false,
      showMap: false,
      contactBar: [
        {
          id: 'gzh',
          title: '公众号',
          icon: '',
          qrcode: require('../assets/imgs/gzh-qrcode1.jpg'),
        },
        {
          id: 'xcx',
          title: '小程序',
          icon: '',
          qrcode: require('../assets/imgs/xcx-qrcode.png'),
        },
        {
          id: 'email',
          title: '电子邮箱',
          icon: '',
          desc: 'ad@inshealth.cn',
        },
        {
          id: 'tel',
          title: '联系我们',
          icon: '',
          desc: '021-61984743',
        },
        {
          id: 'kf',
          title: '客服热线',
          icon: '',
          desc: '400-6065-033',
        },
      ],
      topInfos: [
        {
          title: '10000+',
          label: '二、三级医院',
        },
        {
          title: '600+',
          label: '美国医院',
        },
        {
          title: '340+',
          label: 'DTP药店',
        },
        {
          title: '220',
          subtitle: '城市',
          label: '直付城市覆盖',
        },
        {
          title: '欧美亚',
          label: '海外医疗覆盖',
        },
        {
          title: '400+',
          subtitle: '城市',
          label: '护理服务覆盖',
        },
      ],
      productList: [
        {
          id: 0,
          img: require('../assets/imgs/product-1.png'),
          title: '产品开放平台',
          desc: '通过6年的技术迭代与升级，英仕健康的医疗保险开放平台已覆盖从前端产品销售到核心运营作业的全生命周期。加快了健康险领域的技术推进与数字化升级，全面提高运营作业效率及客户服务能力。功能包含了：产品工厂、自动配置、API开发者平台、在线核保、AI客服、可回溯系统、CRM&OP等各个环节。',
        },
        {
          id: 1,
          img: require('../assets/imgs/product-2.png'),
          title: '智能风控',
          desc: '当保险渠道的用户需要在保险公司投保时，保险公司首先要评估该客户风险，以前风险评估多依靠人工分析判断，但是人工判断会消耗巨大的人力成本，依赖人工经验，因为医疗信息非常复杂，涉及的数据维度多种多样，而医疗险的件均保费又很低，因此投入巨大的人力进行人工审核的成本太高。\n英仕健康的智能核保平台对保险渠道的客户画像进行建模，基于AI训练将人工核保思维设定成系统化的逻辑，从而测算客户的投保风险，最后和保险公司共同设计出符合其需求的保险产品和投保流程。',
        },
        {
          id: 2,
          img: require('../assets/imgs/product-3.png'),
          title: '智能控费',
          desc: '英仕健康的理赔系统利用OCR、NLP技术将各类信息标准量化后，导入智能核保平台，由系统自动得出各种结论。通过“微信小程序+智能核赔系统+知识图谱数据库”的结合，完成从保险咨询、申请、审核、到赔付等的全流程个性化服务，可帮助商保公司建立完善的信息流程体系，提高理赔工作效率的。',
        },
        {
          id: 3,
          img: require('../assets/imgs/product-4.png'),
          title: '健康管理平台',
          desc: '通过建立保险与医疗资源的深度结合，我们在健康医疗管理上的业务也一直保持着增长，其中健康险业务合作医疗机构覆盖全国23个省和所有的直辖市、自治区，总计近3000家综合三级以上医院。\n服务的包含了：住院直付、绿通服务、肿瘤精准治疗、第二诊疗意见、多学科会诊、院外靶向药直付、海外医疗、基因检测（精准医疗）、体检服务、术后护理、电话医生、海外特药服务等。并且我们的健康管理系统对于各种服务启动的限制条件、流程监控以及服务后评估进行全流程的管理，不断的去提升健康管理服务的各个环节。',
        },
      ],
      serviceList: [
        {
          img: require('../assets/imgs/service-1.png'),
          title: '就医绿色通道',
          desc: '快速协调与客户疾病对应的医院、科室、专家，预约问诊与治疗；服务覆盖全国31个省/直辖市1000+家三甲医院',
        },
        {
          img: require('../assets/imgs/service-2.png'),
          title: '住院医疗费用直付/垫付',
          desc: '在保障范围内直付/垫付客户住院治疗的费用；服务覆盖全国31省/直辖市，220个城市的二级及以上公立医院',
        },
        {
          img: require('../assets/imgs/service-3.png'),
          title: '国内/海外第二诊疗意见',
          desc: '根据客户病情与既往就诊资料，协调安排北上广或海外医学专家提供书面的二次诊疗意见，协助客户规划就医和治疗方案',
        },
        {
          img: require('../assets/imgs/service-4.png'),
          title: 'MDT多学科会诊',
          desc: '根据客户病情与既往就诊资料，协调安排北上广医学专家团队进行多学科协同的会诊，解决疑难、复杂疾病的病情判断与治疗方案规划难题',
        },
        {
          img: require('../assets/imgs/service-5.png'),
          title: '靶向用药基因检测',
          desc: '针对需要应用靶向药治疗的客户，安排用药前的基因检测，为合理规划靶向用药方案、提高用药效率、应对耐药提供依据，实现科学精准治疗与控费',
        },
      ],
      partnerList: [
        {
          img: require('../assets/imgs/partners/1.png'),
        },
        {
          img: require('../assets/imgs/partners/2.png'),
        },
        {
          img: require('../assets/imgs/partners/3.png'),
        },
        {
          img: require('../assets/imgs/partners/4.png'),
        },
        {
          img: require('../assets/imgs/partners/5.png'),
        },
        {
          img: require('../assets/imgs/partners/6.png'),
        },
        {
          img: require('../assets/imgs/partners/7.png'),
        },
        {
          img: require('../assets/imgs/partners/8.png'),
        },
        {
          img: require('../assets/imgs/partners/9.png'),
        },
        {
          img: require('../assets/imgs/partners/10.png'),
        },
        {
          img: require('../assets/imgs/partners/11.png'),
        },
        {
          img: require('../assets/imgs/partners/12.png'),
        },
        {
          img: require('../assets/imgs/partners/13.png'),
        },
        {
          img: require('../assets/imgs/partners/14.png'),
        },
        {
          img: require('../assets/imgs/partners/15.png'),
        },
        {
          img: require('../assets/imgs/partners/16.png'),
        },
        {
          img: require('../assets/imgs/partners/17.png'),
        },
        {
          img: require('../assets/imgs/partners/18.png'),
        },
        {
          img: require('../assets/imgs/partners/19.png'),
        },
        {
          img: require('../assets/imgs/partners/20.png'),
        },
        {
          img: require('../assets/imgs/partners/21.png'),
        },
        {
          img: require('../assets/imgs/partners/22.png'),
        },
        {
          img: require('../assets/imgs/partners/23.png'),
        },
        {
          img: require('../assets/imgs/partners/24.png'),
        },
      ],
    };
  },
  watch: {
    '$route.query'(e) {
      if (e?.scroll) {
        this.$nextTick(() => {
          this.$el.querySelector(`#index-${e.scroll}`)?.scrollIntoView({});
        });
      }
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.scroll();
    // });
    this.initMap();
    window.addEventListener('scroll', this.scroll, true);
    if (this.$route.query?.scroll) {
      this.$nextTick(() => {
        this.$el.querySelector(`#index-${this.$route.query?.scroll}`)?.scrollIntoView({});
      });
    }
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
      })();
    };
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll, true);
  },
  methods: {
    initMap() {
      //定义地图中心点坐标
      // eslint-disable-next-line no-undef
      var center = new TMap.LatLng(31.276284372089336, 121.52018001744037);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      // eslint-disable-next-line no-undef
      var map = new TMap.Map(document.getElementById('container'), {
        center: center, //设置地图中心点坐标
        zoom: 19, //设置地图缩放级别
      });

      // eslint-disable-next-line
      var markerLayer = new TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          // eslint-disable-next-line no-undef
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: 'myStyle', //指定样式id
            // eslint-disable-next-line no-undef
            position: new TMap.LatLng(31.276284372089336, 121.52018001744037), //点标记坐标位置
            properties: {
              //自定义属性
              title: 'marker1',
            },
          },
        ],
      });
    },
    serviceScroll(e) {
      if (e.target.scrollLeft + e.target.clientWidth >= e.target.scrollWidth - 200) {
        this.serviceScr = false;
        this.serviceScrL = true;
      } else {
        this.serviceScr = true;
        this.serviceScrL = false;
      }
    },
    toMore() {
      this.$router.push({
        path: '/about',
      });
    },
    toProduct(data) {
      this.$router.push({
        path: '/product',
        query: {
          scroll: data.id,
        },
      });
    },
    scrollRight() {
      this.$el.querySelector('.service-list').scrollLeft = this.$el.querySelector('.service-list').clientWidth * 2;
    },
    scrollLeft() {
      this.$el.querySelector('.service-list').scrollLeft = 0;
    },
    scroll() {
      // const h = ($('.page-home').width() / $('body').width()) * $('body').height();
      if (this.$el.querySelector('#index-0').getBoundingClientRect().top < 400 && this.showContact == false) {
        this.showContact = true;
      }
      if (this.$el.querySelector('#index-0').getBoundingClientRect().top >= 400 && this.showContact == true) {
        this.showContact = false;
      }
      // if (this.$el.querySelector('#index-0').getBoundingClientRect()?.top < h / 1.2 && this.showProduct == false) {
      //   this.showProduct = true;
      // }
      // if (this.$el.querySelector('#index-1').getBoundingClientRect()?.top < h / 1.2 && this.showService == false) {
      //   this.showService = true;
      // }
      // if (this.$el.querySelector('#index-3').getBoundingClientRect()?.top < h / 1.2 && this.showPartner == false) {
      //   this.showPartner = true;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-home {
  padding: 280px 160px 0;
  background-image: url(../assets/imgs/banner-1.png);
  background-size: 100% 1080px;
  background-repeat: no-repeat;
  overflow: hidden;
}

.page-title {
  font-size: 50px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
  opacity: 0;

  transform-origin: left;
  animation: fadeInDown 1s forwards, opc 1s forwards;
  .en {
    font-size: 30px;
    font-weight: 400;
    font-family: Helvetica;
    color: #b6b6b6;
    line-height: 30px;
    margin-left: 14px;
  }
}

.page-desc {
  width: 922px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 45px;
  margin-top: 40px;
  opacity: 0;

  transform-origin: left;
  animation: fadeInDown 1s 0.3s, opc 1s 0.3s forwards;
}

.more {
  font-size: 20px;
  font-family: Helvetica;
  color: #ffffff;
  line-height: 70px;
  width: 200px;
  height: 70px;
  background: #2669f0;
  box-shadow: 0px 20px 40px 0px rgba(51, 102, 204, 0.4);
  text-align: center;
  margin: 90px 0 106px;
  cursor: pointer;
  opacity: 0;
  transform-origin: left;
  animation: fadeInDown 1s 0.7s, opc 1s 0.7s forwards;
}

.show-history {
  transform-origin: left;
  animation: fadeInDown 1s 0.3s, opc 1s 0.3s forwards;
}
.show-history-desc {
  transform-origin: left;
  animation: fadeInDown 1s 0.6s, opc 1s 0.6s forwards;
}
.show-history-content {
  transform-origin: left;
  animation: fadeInDown 1s 1s, opc 1s 1s forwards;
}
.show-history-content2 {
  transform-origin: left;
  animation: fadeInDown 2s 1.7s, opc 2s 1.7s forwards;
}
.show-history-content3 {
  transform-origin: left;
  animation: fadeInDown 1s 2.3s, opc 1s 2.3s forwards;
}

.topinfo {
  width: 1600px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 200px;
  opacity: 0;

  transform-origin: left;
  animation: fadeInDown 1s 1s, opc 1s 1s forwards;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;

    animation: zoomIn 1s 1.5s forwards;

    @keyframes zoomIn {
      from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
      }
      to {
        opacity: 1;
      }
    }
    &-title {
      font-size: 50px;
      font-family: HelveticaNeue-CondensedBold, HelveticaNeue;
      font-weight: 700;
      color: #000000;
      line-height: 50px;
      .subtit {
        font-size: 20px;
        line-height: 20px;
      }
    }
    &-label {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
      margin-top: 30px;
    }
  }
}
.m-title {
  // opacity: 0;
  font-size: 30px;
  font-family: Helvetica;
  color: #ebebeb;
  line-height: 30px;
  position: relative;
  text-align: center;
  margin-top: 60px;
  .t-top {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 90px));
    font-size: 50px;
    font-family: HelveticaNeue-CondensedBlack, HelveticaNeue;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    padding-top: 160px;
    // margin-top: -10px;
  }
}

.m-desc {
  // opacity: 0;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 0 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product {
  padding-top: 100px;
}
.product-list {
  // opacity: 0;
  display: flex;
  justify-content: space-between;

  &-item {
    width: 377px;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    margin: 0 15px;

    img {
      width: 100%;
    }
    &-title {
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
      margin-top: -50px;
      padding: 0 40px;
      position: relative;

      &::after {
        content: '';
        width: 40px;
        height: 3px;
        background: #2669f0;
        position: absolute;
        left: 40px;
        bottom: -20px;
      }
    }
    &-desc {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 38px;
      padding: 0 40px;
      margin-top: 50px;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
    &-more {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2669f0;
      line-height: 18px;
      padding: 0 40px;
      margin-top: 30px;
      cursor: pointer;
    }
  }
}

.service {
  margin-top: 315px;
  &-list {
    width: 1920px;
    display: flex;
    margin-top: 120px;
    padding-left: 160px;
    padding-right: 160px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &-box {
      width: 1920px;
      position: relative;
      left: -160px;
      .icon {
        width: 176px;
        position: absolute;
        right: -20px;
        top: calc(60%);
        transform: translateY(-50%);
        z-index: 2;
      }
      .icon-l {
        left: 20px;
        transform-origin: center;
        top: calc(60% - 216px);
        transform: rotateZ(180deg) translateY(-50%);
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
        height: 100%;
        background: linear-gradient(to right, #fff, transparent);
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 200px;
        height: 100%;
        background: linear-gradient(to right, transparent, #fff);
        z-index: 1;
      }
    }
    &-item {
      width: 420px;
      flex-shrink: 0;
      margin-right: 40px;
      img {
        // opacity: 0;
        width: 100%;
      }

      &-title {
        // opacity: 0;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 24px;
        margin: 50px 0 30px;
      }
      &-desc {
        // opacity: 0;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 36px;
      }
    }
  }
}
.partner {
  margin-top: 316px;
  margin-bottom: -100px;
  @for $idx from 0 through 3 {
    &-item-#{$idx} {
      animation: fadeInDown 1s #{$idx/2 + 1}s, opc 1s #{$idx/2 + 1}s forwards;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 131px;
    &-item {
      // opacity: 0;
      width: 16.6%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 225px;
      height: 85px;
      margin-bottom: 85px;
    }
  }
}
.location {
  margin-top: 316px;
  // margin-bottom: -100px;
  @for $idx from 0 through 3 {
    &-item-#{$idx} {
      animation: fadeInDown 1s #{$idx/2 + 1}s, opc 1s #{$idx/2 + 1}s forwards;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    &-item {
      // opacity: 0;
      width: 16.6%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-txt {
    font-size: 22px;
    margin-top: 40px;
    display: flex;
    align-items: center;
  }
}

.contact-bar {
  position: fixed;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  height: 554px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
  // padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  &-item {
    width: 100%;
    .inner-box {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      .inner-item-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        border-bottom: 1px solid #f5f5f5;
        .icon-gzh {
          width: 67px;
          height: 67px;
          background-image: url('../assets/imgs/icons/gzh.png');
          background-size: 100% 100%;
          &:hover {
            background-image: url('../assets/imgs/icons/gzh-c.png');
          }
        }
        .icon-xcx {
          width: 67px;
          height: 67px;
          background-image: url('../assets/imgs/icons/xcx.png');
          background-size: 100% 100%;
          &:hover {
            background-image: url('../assets/imgs/icons/xcx-c.png');
          }
        }
        .icon-email {
          width: 67px;
          height: 67px;
          background-image: url('../assets/imgs/icons/email.png');
          background-size: 100% 100%;
          &:hover {
            background-image: url('../assets/imgs/icons/email-c.png');
          }
        }
        .icon-tel {
          width: 67px;
          height: 67px;
          background-image: url('../assets/imgs/icons/tel.png');
          background-size: 100% 100%;
          &:hover {
            background-image: url('../assets/imgs/icons/tel-c.png');
          }
        }
        .icon-kf {
          width: 67px;
          height: 67px;
          background-image: url('../assets/imgs/icons/kf.png');
          background-size: 100% 100%;
          &:hover {
            background-image: url('../assets/imgs/icons/kf-c.png');
          }
        }
      }
    }
    &:hover {
      .inner-box {
        .inner-item-box {
          color: #2669f0;
        }
      }
    }
    &:last-child {
      .inner-item-box {
        border: none;
      }
    }
    flex: 1;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #b3b3b3;
    line-height: 16px;
    &:last-child {
      border: none;
    }
  }
}
.popover {
  padding: 10px;
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    white-space: nowrap;
  }
  img {
    width: 200px;
    height: 200px;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .page-home {
    padding: 60px 80px 0;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .page-title {
    font-size: 50px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 50px;
    opacity: 0;

    transform-origin: left;
    animation: fadeInDown 1s forwards, opc 1s forwards;
    .en {
      font-size: 30px;
      font-weight: 400;
      font-family: Helvetica;
      color: #b6b6b6;
      line-height: 30px;
      margin-left: 14px;
    }
  }

  .page-desc {
    width: 100%;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 45px;
    margin-top: 40px;
    opacity: 0;

    animation: fadeInDown 1s 0.3s, opc 1s 0.3s forwards;
  }

  .more {
    font-size: 20px;
    font-family: Helvetica;
    color: #ffffff;
    line-height: 70px;
    width: 200px;
    height: 70px;
    background: #2669f0;
    box-shadow: 0px 20px 40px 0px rgba(51, 102, 204, 0.4);
    text-align: center;
    margin: 60px 0 60px;
    cursor: pointer;
    opacity: 0;
    transform-origin: left;
    animation: fadeInDown 1s 0.7s, opc 1s 0.7s forwards;
  }

  .topinfo {
    width: 100%;
    height: auto;
    // height: 180px;
    background: #ffffff;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    // justify-content: space-around;
    flex-wrap: wrap;
    padding: 40px 40px 0;
    margin-bottom: 200px;
    opacity: 0;

    transform-origin: left;
    animation: fadeInDown 1s 1s, opc 1s 1s forwards;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-bottom: 40px;
      width: 33%;
      opacity: 0;

      animation: zoomIn 1s 1.5s forwards;
      @keyframes zoomIn {
        from {
          opacity: 0;
          transform: scale3d(0.3, 0.3, 0.3);
        }
        to {
          opacity: 1;
        }
      }
      &-title {
        font-size: 50px;
        font-family: HelveticaNeue-CondensedBold, HelveticaNeue;
        font-weight: 700;
        color: #000000;
        line-height: 50px;
        .subtit {
          font-size: 20px;
          line-height: 20px;
        }
      }
      &-label {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 20px;
        margin-top: 30px;
      }
    }
  }
  .m-title {
    // opacity: 0;
    font-size: 30px;
    font-family: Helvetica;
    color: #ebebeb;
    line-height: 30px;
    position: relative;
    text-align: center;
    margin-top: 0;
    .t-top {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - 90px));
      font-size: 50px;
      font-family: HelveticaNeue-CondensedBlack, HelveticaNeue;
      font-weight: bold;
      color: #000000;
      line-height: 50px;
      padding-top: 160px;
      // margin-top: -10px;
    }
  }

  .m-desc {
    // opacity: 0;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 0 20px;
    text-align: center;
  }
  .product {
    // padding-top: 30px;
    margin-top: -50px;
  }
  .product-list {
    // opacity: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-item {
      // width: 377px;
      width: 45%;
      flex-shrink: 0;
      background: #f9f9f9;
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      margin: 0 15px 20px;

      img {
        width: 100%;
      }
      &-title {
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
        margin-top: -50px;
        padding: 0 20px;
        position: relative;

        &::after {
          content: '';
          width: 40px;
          height: 3px;
          background: #2669f0;
          position: absolute;
          left: 20px;
          bottom: -20px;
        }
      }
      &-desc {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
        padding: 0 20px;
        margin-top: 50px;
        display: -webkit-box;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      &-more {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2669f0;
        line-height: 18px;
        padding: 0 20px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }

  .service {
    margin-top: 235px;
    &-list {
      width: 100%;
      display: flex;
      margin-top: 120px;
      padding-left: 80px;
      padding-right: 80px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      &-box {
        width: 100vw;
        position: relative;
        left: -80px;
        .icon {
          width: 176px;
          position: absolute;
          right: -20px;
          top: calc(60%);
          transform: translateY(-50%);
          z-index: 2;
        }
        .icon-l {
          left: 20px;
          transform-origin: center;
          top: calc(60% - 216px);
          transform: rotateZ(180deg) translateY(-50%);
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 200px;
          height: 100%;
          background: linear-gradient(to right, #fff, transparent);
          z-index: 1;
        }
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 200px;
          height: 100%;
          background: linear-gradient(to right, transparent, #fff);
          z-index: 1;
        }
      }
      &-item {
        width: 420px;
        flex-shrink: 0;
        margin-right: 40px;
        img {
          // opacity: 0;
          width: 100%;
        }

        &-title {
          // opacity: 0;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 24px;
          margin: 50px 0 30px;
        }
        &-desc {
          // opacity: 0;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 36px;
        }
      }
    }
  }
  .partner {
    margin-top: 256px;
    @for $idx from 0 through 3 {
      &-item-#{$idx} {
        animation: fadeInDown 1s #{$idx/2 + 1}s, opc 1s #{$idx/2 + 1}s forwards;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 131px;
      &-item {
        // opacity: 0;
        width: 33.3%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 225px;
        height: 85px;
        margin-bottom: 85px;
      }
    }
  }
  .location {
    margin-top: 256px;
    // margin-bottom: -100px;
    @for $idx from 0 through 3 {
      &-item-#{$idx} {
        animation: fadeInDown 1s #{$idx/2 + 1}s, opc 1s #{$idx/2 + 1}s forwards;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 131px;
      &-item {
        // opacity: 0;
        width: 33.3%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-txt {
      font-size: 26px;
      margin-top: 40px;
      display: flex;
      align-items: center;
    }
  }

  .contact-bar {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 554px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
    // padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    &-item {
      width: 100%;
      .inner-box {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        .inner-item-box {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          border-bottom: 1px solid #f5f5f5;
          .icon-gzh {
            width: 67px;
            height: 67px;
            background-image: url('../assets/imgs/icons/gzh.png');
            background-size: 100% 100%;
            &:hover {
              background-image: url('../assets/imgs/icons/gzh-c.png');
            }
          }
          .icon-xcx {
            width: 67px;
            height: 67px;
            background-image: url('../assets/imgs/icons/xcx.png');
            background-size: 100% 100%;
            &:hover {
              background-image: url('../assets/imgs/icons/xcx-c.png');
            }
          }
          .icon-email {
            width: 67px;
            height: 67px;
            background-image: url('../assets/imgs/icons/email.png');
            background-size: 100% 100%;
            &:hover {
              background-image: url('../assets/imgs/icons/email-c.png');
            }
          }
          .icon-tel {
            width: 67px;
            height: 67px;
            background-image: url('../assets/imgs/icons/tel.png');
            background-size: 100% 100%;
            &:hover {
              background-image: url('../assets/imgs/icons/tel-c.png');
            }
          }
          .icon-kf {
            width: 67px;
            height: 67px;
            background-image: url('../assets/imgs/icons/kf.png');
            background-size: 100% 100%;
            &:hover {
              background-image: url('../assets/imgs/icons/kf-c.png');
            }
          }
        }
      }
      &:hover {
        .inner-box {
          .inner-item-box {
            color: #2669f0;
          }
        }
      }
      &:last-child {
        .inner-item-box {
          border: none;
        }
      }
      flex: 1;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #b3b3b3;
      line-height: 16px;
      &:last-child {
        border: none;
      }
    }
  }
  .popover {
    padding: 10px;
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
      white-space: nowrap;
    }
    img {
      width: 200px;
      height: 200px;
    }
  }
}
</style>

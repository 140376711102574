<template>
  <div class="comp-headerh5">
    <div :class="`inner ${bac ? 'bac' : ''}`">
      <div class="inner-top">
        <div class="logo">
          <img src="../assets/imgs/logo.png" alt="" />
        </div>
        <div class="icon">
          <img v-if="!showNav" @click="showNav = true" src="../assets/imgs/nav-icon.png" alt="" />
          <img v-else @click="showNav = false" src="../assets/imgs/nav-close.png" alt="" />
        </div>
      </div>
      <div class="nav" v-if="showNav">
        <div :class="`nav-item ${$route.path === item.path ? 'checked' : ''}`" v-for="item in navList" :key="item.title" @click="showNav = false">
          <router-link :to="item.path">
            <div class="inner-item">
              <div>{{ item.title }}</div>
              <img src="../assets/imgs/icon-right-s.png" alt="" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompHeader',

  data() {
    return {
      bac: false,
      showNav: false,
      navList: [
        { title: '首页', path: '/' },
        { title: '产品服务', path: '/product' },
        { title: '关于我们', path: '/about' },
        { title: '企业新闻', path: '/news' },
        { title: '加入我们', path: '/join' },
      ],
    };
  },

  mounted() {
    window.addEventListener('scroll', this.scroll, true);
  },

  methods: {
    scroll() {
      if (document.body.scrollTop > 10 && this.bac == false) {
        this.bac = true;
        return;
      }
      if (document.body.scrollTop <= 10 && this.bac == true) {
        this.bac = false;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-headerh5 {
  display: none;
  width: 100%;
  height: 130px;
  background: #fff;

  .inner {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    padding: 40px 80px;
    background: #fff;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .bac {
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.05);
  }
  .logo {
    width: 132px;
    height: 50px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav {
    height: 100vh;
    letter-spacing: 2px;
    margin-top: 40px;
    &-item {
      width: 100%;
      flex-shrink: 0;
      font-size: 34px;
      color: #000;
      border-bottom: 1px solid #eee;

      .inner-item {
        padding: 60px 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 13px;
          height: 21px;
        }
      }

      a {
        color: #000;
        font-weight: 400;
      }
    }
    .checked {
      a {
        color: #2669f0;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .comp-headerh5 {
    display: block;
  }
}
</style>

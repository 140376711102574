<template>
  <div id="app">
    <Header />
    <HeaderH5 />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import HeaderH5 from './components/HeaderH5.vue';
import Footer from './components/Footer.vue';

export default {
  data() {
    return {};
  },
  mounted() {},
  components: { Header, HeaderH5, Footer },
};
</script>

<style lang="scss">
@import url(./assets/css/common.css);
@import 'animate.css';

#app {
  /* prettier-ignore */
  // min-width: 1000PX;
  overflow: hidden;

  @keyframes opc {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

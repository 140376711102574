<template>
  <div :class="`comp-header ${bac ? 'bac' : ''}`">
    <div class="logo">
      <img src="../assets/imgs/logo.png" alt="" />
    </div>
    <div class="nav">
      <div :class="`nav-item ${$route.path === item.path ? 'checked' : ''}`" v-for="item in navList" :key="item.title">
        <router-link :to="item.path">{{ item.title }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompHeader',

  data() {
    return {
      bac: false,
      navList: [
        { title: '首页', path: '/' },
        { title: '产品服务', path: '/product' },
        { title: '关于我们', path: '/about' },
        { title: '企业新闻', path: '/news' },
        { title: '加入我们', path: '/join' },
      ],
    };
  },

  mounted() {
    window.addEventListener('scroll', this.scroll, true);
  },

  methods: {
    scroll() {
      if (document.body.scrollTop > 100 && this.bac == false) {
        this.bac = true;
        return;
      }
      if (document.body.scrollTop <= 100 && this.bac == true) {
        this.bac = false;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-header {
  min-width: 1000px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 30px 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  .logo {
    width: 132px;
    height: 50px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav {
    display: flex;
    letter-spacing: 2px;
    &-item {
      flex-shrink: 0;
      padding: 0 50px;
      font-size: 18px;
      color: #000;
      a {
        color: #000;
        font-weight: 400;
      }
    }
    .checked {
      a {
        color: #2669f0;
        font-weight: 700;
      }
    }
  }
}

.bac {
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.05);
  .nav {
    .checked {
      position: relative;
      &::after {
        content: '';
        width: 40px;
        height: 3px;
        background: #2669f0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -38px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .comp-header {
    display: none;
  }
}
</style>

<template>
  <div class="comp-footer">
    <div class="top">
      <div class="info">
        <div class="logo">
          <img src="../assets/imgs/logo.png" alt="" />
        </div>
        <div class="txt">
          英仕健康于2017年2月在上海成立，作为国内领先的健康医疗险管理平台，专注于健康险产品创新设计、全流程风控管理，通过建立保险与医疗资源的深度结合，运用智能核保、HMO、PMB等方式进行健康险全流程管理。
        </div>
        <div class="icons" v-if="false">
          <img src="../assets/imgs/icon-web.png" alt="" />
          <img src="../assets/imgs/icon-wechat.png" alt="" />
        </div>
      </div>
      <div class="link-lists">
        <div class="list" v-for="list in links" :key="list.title">
          <div class="list-title">{{ list.title }}</div>
          <div class="list-item" v-for="item in list.items" :key="item.label" @click="jumpUrl(item)">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <span class="copy-right">Copyright © 2017上海杏涛网络科技有限公司 版权所有</span>
      <a href="https://beian.miit.gov.cn/">沪ICP备17013269号-1</a>

      <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002006055"> <img src="../assets/imgs/batb.png" />沪公网安备 31011002006055号 </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompFooter',

  data() {
    return {
      links: [
        {
          title: '首页',
          items: [
            { label: '关于我们', url: '/' },
            { label: '产品介绍', url: '/?scroll=0' },
            { label: '服务介绍', url: '/?scroll=1' },
            { label: '优势介绍', url: '/?scroll=2' },
            { label: '合作伙伴', url: '/?scroll=3' },
            { label: '联系我们', url: '/?scroll=4' },
          ],
        },
        {
          title: '产品服务',
          items: [
            { label: '智能风控', url: '/product?scroll=1' },
            { label: '智能控费', url: '/product?scroll=2' },
            { label: '产品开放平台', url: '/product?scroll=0' },
            { label: '健康管理平台', url: '/product?scroll=3' },
            { label: '隐私政策', url: '/privacy' },
          ],
        },
        {
          title: '公司介绍',
          items: [
            { label: '公司介绍', url: '/about' },
            { label: '发展历程', url: '/about?scroll=0' },
            { label: '资质展示', url: '/about?scroll=1' },
            { label: '荣誉奖项', url: '/about?scroll=2' },
          ],
        },
        {
          title: '企业动态',
          items: [
            { label: '最新动态', url: '/news' },
            { label: '历史动态', url: '/news' },
          ],
        },
        {
          title: '加入我们',
          items: [{ label: '招聘信息', url: '/join' }],
        },
      ],
    };
  },

  mounted() {},

  methods: {
    jumpUrl(data) {
      if (data.url === this.$route.fullPath) {
        this.$router.replace({
          path: data.url,
          query: {
            t: new Date().getTime(),
          },
        });
        return;
      }
      this.$router.push(data.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-footer {
  margin-top: 300px;
  .top {
    padding: 0 160px;
    display: flex;
    .logo {
      width: 158px;
      height: 60px;
      margin-bottom: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      width: 613px;
      .txt {
        line-height: 40px;
        font-size: 20px;
        margin-bottom: 60px;
      }
      .icons {
        display: flex;
        img {
          width: 32px;
          height: 32px;
          margin-right: 20px;
        }
      }
    }

    .link-lists {
      margin-left: 217px;
      flex: 1;
      display: flex;
      justify-content: space-around;
      .list {
        font-size: 20px;
        &-title {
          font-weight: 600;
          margin-bottom: 40px;
          min-width: 110px;
        }
        &-item {
          margin-bottom: 24px;
          cursor: pointer;
          &:hover {
            color: #2669f0;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background: #f0f0f0;
    text-align: center;
    font-size: 14px;
    line-height: 50px;
    color: #888888;
    margin-top: 160px;

    .copy-right {
      margin-right: 30px;
    }

    a {
      display: flex;
      align-items: center;
      color: #888888;
      margin-right: 10px;
    }
    img {
      width: 18px;
      //prettier-ignore
      min-width: 12PX;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .comp-footer {
    margin-top: 200px;
    .top {
      padding: 0 80px;
      flex-direction: column;

      .info {
        width: 90%;
        .txt {
          // font-size: 26px;
          line-height: 2em;
        }
      }

      .link-lists {
        margin-top: 30px;
        margin-left: 0;
        justify-content: space-between;
        .list {
          flex-shrink: 0;
          // font-size: 26px;
        }
      }
    }

    .bottom {
      height: auto;
      padding: 30px 0 60px;
      flex-direction: column;
      line-height: 2em;

      img {
        // prettier-ignore
        min-width: 8PX;
      }
    }
  }
}
</style>
